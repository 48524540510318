<template>
  <div>
    <van-cell title="程序下载">
      <template #right-icon>
        <van-checkbox v-model="llmd01_Step1.chengXuXiaZai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="元件固定">
      <template #right-icon>
        <van-checkbox v-model="llmd01_Step1.yuanJianGuDing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="llmd01_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.llmd01_SignNumber.status!=0" round block @click="save_LLMD01_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.llmd01_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.llmd01_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      llmd01_Step1: {},
    };
  },
  methods: {
    getLLMD01_Step1() {
      let that = this;
      that.axios
        .post("/LLMD01/GetLLMD01_Step1", { onlyText: that.signNumber })
        .then(function (response) {
          that.llmd01_Step1 = response.data.data;
        });
    },
    save_LLMD01_Step1() {
      let that = this;
      that.axios
        .post("LLMD01/UpdateLLMD01_Step1", that.llmd01_Step1)
        .then(function (response) {
          that.llmd01_Step1 = response.data.data;
          that.$notify({ type: "success", message: response.data.msg });
        });
    },
  },
  mounted() {
    let that = this;
    that.getLLMD01_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>